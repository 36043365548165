import Alpine from 'alpinejs'
import '../css/app.css'

window.Alpine = Alpine

Alpine.start()

//SwiftType Search
import './components/search'

//FANUC italic i reformatting scripts
import './components/fanuc-product-renaming'

// Swiper carousel library
// https://swiperjs.com/

// Import Swiper JS
import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);


// Import Swiper styles (CSS)
import 'swiper/swiper-bundle.min.css';

// Initialize Swiper
const swiper = new Swiper('.swiper-container', {
    loop: document.querySelectorAll('.swiper-slide').length >= 4,
    slidesPerView: 1.1,
    centeredSlides: true,

    breakpoints: {
        768: {
            slidesPerView: 2.8,
            centeredSlides: false,
            spaceBetween: 24,
            loop: document.querySelectorAll('.swiper-slide').length >= 3,
        },
        1024: {
            slidesPerView: 3.2,
            centeredSlides: false,
            spaceBetween: 24,
            loop: document.querySelectorAll('.swiper-slide').length >= 4,
        },
        1280: {
            slidesPerView: 4.8,
            centeredSlides: false,
            spaceBetween: 24,
            loop: document.querySelectorAll('.swiper-slide').length >= 5,
        },
        1536: {
            slidesPerView: 5.2,
            centeredSlides: false,
            spaceBetween: 24,
            loop: document.querySelectorAll('.swiper-slide').length >= 6,
        },
        1800: {
            slidesPerView: 6.2,
            centeredSlides: false,
            spaceBetween: 24,
            loop: document.querySelectorAll('.swiper-slide').length >= 7,
        },

    },

    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
    },
});

// Import Medium Zoom Library
// https://github.com/francoischalifour/medium-zoom
import mediumZoom from 'medium-zoom'

mediumZoom('.zoom', {
    margin: 24,
    background: '#1B2022',
    scrollOffset: 0,
})

// Animate On Scroll Options
// https://github.com/michalsnik/aos
import AOS from 'aos'

AOS.init({
    disable: 'phone',
    duration: 400,
    offset: -80,
    once: true,
})

// FSLightbox Pro Version
// import './js/fslightbox'
// fsLightboxInstances['gallery'].props.showThumbsOnMount = true;

// require('fslightbox');


